import React, { useContext, Suspense,useState } from "react";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";


import { AppContext } from "../AppContext";

const Orderpage = () => {
  const { allOrders } = useContext(AppContext);

  const [currentRowData,setCurrentRowData] = useState([])

  //model show hide
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setCurrentRowData(data)
    setShow(true);
  }

  const columns = [
    // {
    //   field: "$id",
    //   headerName: "ID",
    //   width: 90,
    // },
    {
      field: "user_name",
      headerName: "Person Name",
      width: 200,
    },
    {
      field: "user_phone",
      headerName: "Person Phone",
      width: 200,
    },
    {
      field: "user_address",
      headerName: "Address",
      width: 480,
    },
    {
      field: "user_pincode",
      headerName: "Pincode",
      width: 120,
    },
    {
      field: "user_email",
      headerName: "Email",
      width: 200,
    },

    {
      field: "action",
      headerName: "Details",
      width: 200,
      type: "number",
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "#f33155",
              padding: "6px 20px",
              border: "none",
              color: "#fff",
              textTransform: "uppercase",
            }}
            className="Details"
            onClick={() => handleShow(params.row.user_orders)}
          >
            Details
          </button>
        );
      },
    },
  ];

  const productCol = [
    {
      field: "product_image",
      headerName: "Product Image",
      // type: "number",
      width: 150,
      renderCell: (params) => {
        return (
          <img
            style={{ width: "60%" }}
            src={params.row.product_image}
            alt="products"
          />
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product name",
      width: 280,
    },
    {
      field: "product_description",
      headerName: "Product Description",
      width: 480,
    }
  ]



  return (
    <div id="page-orders">
      <div className="order-header">
        <h2>Order List</h2>
      </div>
      <Suspense fallback={"Loading...."}>
        <div className="order-table">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={allOrders}
              columns={columns}
              rowHeight={80}
              checkboxSelection
              getRowId={(row) => row.id}
            />
          </Box>
        </div>
      </Suspense>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ordered Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={currentRowData}
          columns={productCol}
          rowHeight={80}
          checkboxSelection
          getRowId={(row) => row.id}
        />
        </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Orderpage;
