import React, { Suspense, useEffect, useState,useContext } from "react";
import Modal from "react-bootstrap/Modal";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import { storage, db } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection, deleteDoc,doc } from "firebase/firestore";
import { AppContext } from "../AppContext";

const Productpage = () => {
  
  const {allProducts} = useContext(AppContext)

  //productDetails
  const [productName, setProductName] = useState("");
  const [productPoints, setProductPoints] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);

  //model show hide
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleUploadProduct = async () => {
    try {
      const storageRef = ref(storage, imageFile.name);
      await uploadBytes(storageRef, imageFile);

      // Get the download URL of the uploaded image
      const imageUrl = await getDownloadURL(storageRef);
      console.log(imageUrl);

      // Create a new document in the "products" collection
      const docRef = await addDoc(collection(db, "products"), {
        product_name: productName,
        product_coins: productPoints,
        product_description: productDescription,
        product_image: imageUrl,
      });

      console.log(docRef);
      window.location.reload();
      alert("Product Uploaded");

      setProductName("");
      setProductPoints("");
      setProductDescription("");
      setImageFile(null);
    } catch (error) {
      console.log("Upload product =>", error);
    }
  };

  const handleDeleteProduct = async (product) => {
    await deleteDoc(doc(db, "products", product));
    window.location.reload();
    alert("Product Deleted");
  }

  useEffect(() => {

  }, []);

  const columns = [
    // {
    //   field: "$id",
    //   headerName: "ID",
    //   width: 90,
    // },
    {
      field: "product_image",
      headerName: "Product Image",
      // type: "number",
      width: 150,
      renderCell: (params) => {
        return (
          <img
            style={{ width: "60%" }}
            src={params.row.product_image}
            alt="products"
          />
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product name",
      width: 280,
    },
    {
      field: "product_description",
      headerName: "Product Description",
      width: 480,
    },
    {
      field: "product_coins",
      headerName: "Product Points",
      type: "number",
      width: 220,
    },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      type: "number",
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "#f33155",
              padding: "6px 20px",
              border: "none",
              color: "#fff",
              textTransform: "uppercase",
            }}
            className="delete"
            onClick={() => handleDeleteProduct(params.row.id)}
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <div id="page-product">
      <div className="product-header">
        <h2>Product List</h2>
        <button onClick={handleShow}>Add Product</button>
      </div>
      <Suspense fallback={"Loading...."}>
        <div className="product-table">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={allProducts}
              columns={columns}
              rowHeight={120}
              checkboxSelection
              getRowId={(row) => row.product_name}
            />
          </Box>
        </div>
      </Suspense>
      <Modal
        size="ml"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="add-product-form">
            <div className="p-field">
              <label htmlFor="productname">Product Name</label>
              <input
                type="text"
                placeholder="Product Name"
                id="productname"
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="points">Points</label>
              <input
                type="number"
                placeholder="Points"
                id="points"
                onChange={(e) => setProductPoints(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="description">Description</label>
              <textarea
                type="number"
                placeholder="Description"
                id="description"
                onChange={(e) => setProductDescription(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="imageupload">Image Upload</label>
              <input
                type="file"
                id="imageupload"
                name="files"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleUploadProduct} id="add-product-btn">
            Add Product
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Productpage;
