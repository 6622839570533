import { useState,useContext } from "react";

import { AppContext } from "../AppContext";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Logo from "../assets/dolphine-logo.png"

const Loginpage = () => {

const { handleUserLoggedIn } = useContext(AppContext);

  const [userEmail,setUserEmail] = useState("");
  const [userPassword,setUserPassword] = useState("")  

  const handleLoggedIn = (e) => {
    e.preventDefault()
    if((userEmail === "dolphinscanner") && (userPassword === "dolphinscanner123")){
        handleUserLoggedIn(true)
        localStorage.setItem("USER",true)
    }else{
        alert("Invalid Username and Password")
    }
  }
  
  return (
    <div id="app-login">
      <div className="container">
        <div className="app-login-form">
          <div className="app-login-logo">
            <img src={Logo} />
          </div>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={userEmail} onChange={(e)=>setUserEmail(e.target.value)}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={userPassword} onChange={(e)=>setUserPassword(e.target.value)}/>
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleLoggedIn}>
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
