import React, { useEffect, Suspense, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { AppContext } from "../AppContext";

import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";

const Userpage = () => {
  const { allUsers } = useContext(AppContext);

  const [userName,setUserName] = useState('');
  const [userPhone,setUserPhone] = useState('');
  const [userEmail,setUserEmail] = useState('');
  const [userProfession,setUserProfession] = useState('');
  const [userCoins,setUserCoins] = useState('');
  const [userAddress,setUserAddress] = useState('');
  const [userPincode,setUserPincode] = useState('');
  const [userVerified,setUserVerified] = useState(false);

  //model show hide
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setUserName(data.user_name)
    setUserPhone(data.user_phone)
    setUserEmail(data.user_email)
    setUserProfession(data.user_profession)
    setUserCoins(data.user_coins)
    setUserAddress(data.user_address)
    setUserPincode(data.user_pincode)
    setUserVerified(data.user_verified)
    setShow(true);
  };

  const handleUpdateUser = async () => {
    try {
      await setDoc(doc(db, "users", userPhone), {
        user_name:userName,
        user_phone:userPhone,
        user_email:userEmail,
        user_profession:userProfession,
        user_coins:userCoins,
        user_address:userAddress,
        user_pincode:userPincode,
        user_verified:userVerified
      },{merge:true});
      setShow(false)
      window.location.reload();
      alert("User Updated!");
    } catch (error) {
      console.log("Update User => ",error)
    }
  };

  useEffect(() => {}, []);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 90,
    // },
    {
      field: "user_verified",
      headerName: "Verified",
      width: 150,
    },
    {
      field: "user_name",
      headerName: "Name",
      // type: "number",
      width: 150,
    },
    {
      field: "user_phone",
      headerName: "Phone",
      width: 160,
    },
    {
      field: "user_email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "user_profession",
      headerName: "Profession",
      width: 200,
    },
    {
      field: "user_coins",
      headerName: "User Coins",
      width: 120,
    },
    {
      field: "user_address",
      headerName: "Address",
      width: 420,
    },
    {
      field: "user_pincode",
      headerName: "Pincode",
      width: 100,
    },
    {
      field: "action",
      headerName: "Details",
      width: 200,
      type: "number",
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "#f33155",
              padding: "6px 20px",
              border: "none",
              color: "#fff",
              textTransform: "uppercase",
            }}
            className="Update"
            onClick={() => handleShow(params.row)}
          >
            Details
          </button>
        );
      },
    },
  ];

  return (
    <div id="page-users">
      <div className="user-header">
        <h2>Users List</h2>
      </div>
      <Suspense fallback={"Loading..."}>
        <div className="users-table">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={allUsers}
              columns={columns}
              rowHeight={60}
              getRowId={(row) => row.id}
              checkboxSelection
            />
          </Box>
        </div>
      </Suspense>
      <Modal
        size="ml"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="add-product-form">
            <div className="p-field">
              <label htmlFor="username">Name</label>
              <input
                type="text"
                placeholder="Name"
                id="username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="userphone">Phone</label>
              <input
                type="text"
                placeholder="Phone"
                id="userphone"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="useremail">Email</label>
              <input
                type="text"
                placeholder="Email"
                id="useremail"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="userprofession">Profession</label>
              <input
                type="text"
                placeholder="Profession"
                id="userprofession"
                value={userProfession}
                onChange={(e) => setUserProfession(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="usercoins">Coins</label>
              <input
                type="text"
                placeholder="Coins"
                id="usercoins"
                value={userCoins}
                onChange={(e) => setUserCoins(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="useraddress">Address</label>
              <input
                type="text"
                placeholder="Address"
                id="useraddress"
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="userpincode">Pincode</label>
              <input
                type="text"
                placeholder="Pincode"
                id="userpincode"
                value={userPincode}
                onChange={(e) => setUserPincode(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="userverified">User Verified</label>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={userVerified}
                    id="userverified"
                    onChange={(e) => setUserVerified(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Verified"
                labelPlacement="end"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => handleUpdateUser()} id="add-product-btn">
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Userpage;
