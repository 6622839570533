import React, { useState } from "react";
import QRCode from "qrcode.react";
import JSZip from "jszip";

const Qrcodepage = () => {
  const [numQRcodes, setNumQRcodes] = useState(10); // Number of QR codes to generate
  const [keyStrings, setKeyStrings] = useState([]);

  const generateKeyString = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substr(2, 8);
    return `${randomString}_${timestamp}`;
  };

  const generateQRcodes = () => {
    const newKeyStrings = [];

    for (let i = 0; i < numQRcodes; i++) {
      const keyString = generateKeyString();
      newKeyStrings.push(keyString);
    }

    setKeyStrings(newKeyStrings);
  };

  const downloadAllQRCode = () => {
    const zip = new JSZip();

    keyStrings.forEach((keyString) => {
      const canvas = document.getElementById(`qrcode-${keyString}`);
      const dataURL = canvas.toDataURL("image/png");
      zip.file(`${keyString}.png`, dataURL.substr(dataURL.indexOf(",") + 1), {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "qrcodes.zip";
      link.click();
    });
  };

  return (
    <div id="page-qrcode">
      <div className="qrcode-header">
        <h2>Generate QRCode</h2>
      </div>
      <div className="qrcode-body">
        <div className="qrcode-form">
          <label htmlFor="numQRcodes">Number of QR codes:</label>
          <input
            type="number"
            id="numQRcodes"
            min="1"
            value={numQRcodes}
            onChange={(e) => setNumQRcodes(parseInt(e.target.value))}
          />
          <button onClick={generateQRcodes} className="btn-generate">Generate QR Codes</button>
          {keyStrings.length > 0 && (
            <button onClick={downloadAllQRCode} className="btn-download">Download All</button>
          )}
        </div>

        <div className="qrcode-list">
          {keyStrings.map((keyString, index) => (
            <div className="block" key={index}>
              <QRCode id={`qrcode-${keyString}`} value={keyString} />
              <p>{keyString}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Qrcodepage;
