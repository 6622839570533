// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCSqPczy6aZi96PgqKVCPaUhNOihBaT1m0",
  authDomain: "dolphinscanner-a98df.firebaseapp.com",
  projectId: "dolphinscanner-a98df",
  storageBucket: "dolphinscanner-a98df.appspot.com",
  messagingSenderId: "460047115501",
  appId: "1:460047115501:web:1583f504ce28e8822918e4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app)