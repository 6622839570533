import React,{useContext} from 'react'

import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

import { AppContext } from './AppContext';

const Topbar = () => {
  const { handleUserLoggedIn } = useContext(AppContext);
  return (
    <div id="app-top">
      <div className="user-name">
        <PersonIcon />
        <p>Admin</p>
      </div>
      <div className="user-logout">
        <LogoutIcon titleAccess='Logout' onClick={()=>handleUserLoggedIn(false)}/>
      </div>
    </div>
  )
}

export default Topbar