import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AppContext } from "./AppContext";

import { db } from "./firebase-config";
import { getDocs, collection } from "firebase/firestore";

//imports
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Homepage from "./pages/Homepage";
import Productpage from "./pages/Productpage";
import Userpage from "./pages/Userpage";
import Orderpage from "./pages/Orderpage";
import Qrcodepage from "./pages/Qrcodepage";
import Loginpage from "./pages/Loginpage";

const App = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleUserLoggedIn = (val) => {
    setIsLoggedIn(val);
    localStorage.removeItem("USER")
  };

  // console.log(allProducts,allUsers)

  const handleAllData = async () => {
    try {
      // all products
      const allProducts = await getDocs(collection(db, "products"));
      const getAllProducts = allProducts.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllProducts(getAllProducts);

      // all users
      const allUsers = await getDocs(collection(db, "users"));
      const getAllusers = allUsers.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllUsers(getAllusers);

      // all users
      const allOrders = await getDocs(collection(db, "orders"));
      const getAllorders = allOrders.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllOrders(getAllorders);
    } catch (error) {
      console.log("Handle All Data =>", error);
    }
  };

  const getCurrentUser = () => {
    const user = localStorage.getItem("USER");
    if(user){
      setIsLoggedIn(true)
    }else{
      setIsLoggedIn(false)
    }
  }

  useEffect(() => {
    getCurrentUser()
    handleAllData();
  }, []);

  return (
    <AppContext.Provider value={{ allProducts, allUsers, allOrders, isLoggedIn, handleUserLoggedIn }}>
      <BrowserRouter>
        {isLoggedIn ? (
          <div id="app-main">
            <Sidebar />
            <div id="app-content">
              <Topbar />
              <div id="app-page">
                <Switch>
                  <Route exact path="/">
                    <Homepage />
                  </Route>
                  <Route exact path="/products">
                    <Productpage />
                  </Route>
                  <Route exact path="/users">
                    <Userpage />
                  </Route>
                  <Route exact path="/orders">
                    <Orderpage />
                  </Route>
                  <Route exact path="/qrcode">
                    <Qrcodepage />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Loginpage />
          </div>
        )}
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
