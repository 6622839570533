import React from "react";
import { NavLink } from "react-router-dom";

//import
import Adminlogo from "./assets/dolphine-logo.png";

import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import QrCodeIcon from '@mui/icons-material/QrCode';

const Sidebar = () => {
  return (
    <div id="app-menu">
      <div id="sidebar-logo">
        <img src={Adminlogo} alt="logo" />
      </div>
      <div id="sidebar-menu">
        <div className="menu-card">
          <NavLink exact to="/">
            <HomeIcon />
            <p>Home</p>
          </NavLink>
        </div>
        <div className="menu-card">
          <NavLink exact to="/products">
            <InventoryIcon />
            <p>Products</p>
          </NavLink>
        </div>
        <div className="menu-card">
          <NavLink exact to="/users">
            <PersonIcon />
            <p>Users</p>
          </NavLink>
        </div>
        <div className="menu-card">
          <NavLink exact to="/orders">
            <ShoppingCartIcon />
            <p>Orders</p>
          </NavLink>
        </div>
        <div className="menu-card">
          <NavLink exact to="/qrcode">
            <QrCodeIcon />
            <p>Generate QRCode</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
